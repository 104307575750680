<template lang="">
    <div>
        <GeneralLedgerOld 
          @onLoadData="onLoadData"
        />
        <ReceiptsPaymentsReport 
            v-if="reloadKey"
            :key="reloadKey"
            @onLoadData="onLoadData"
        />
        <CashAndBank
          v-if="reloadKey"
          :key="reloadKey"
          @onLoadData="onLoadData"
        />
    </div>
</template>
<script setup>
import GeneralLedgerOld from '@/views/page/company/core-accounting/accounting-report/GeneralLedgerOld.vue'
import ReceiptsPaymentsReport from '@/views/page/company/core-accounting/accounting-report/ReceiptsPaymentsReport.vue'
import CashAndBank from '@/views/page/company/core-accounting/accounting-report/CashAndBank.vue';
import { onMounted, ref } from 'vue';
import handleCompany from "@/services/modules/company";
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import pdfPrinter from '@/services/utils/pdf/DailyCashSummary';

const reloadKey = ref(0);
const companyInfo = ref({});
const { fetchCompanyInfo } = handleCompany();
const { exportToPDF } = pdfPrinter();
const route = useRoute();
const companyId = computed(() => route.params.companyId);
const loadingStep = ref(0);
const dataObj = ref({});

const onLoadData = ({data, loadType}) => {
  
  if(loadType === 'general') {
    reloadKey.value += 1;
    loadingStep.value = 0;
    dataObj.value = {};
    return;
  };

  if(loadType === 'pdf') {
    reloadKey.value += 1;
  }

  dataObj.value[(loadType === 'pdf' ? 'general' : loadType)] = data;
  loadingStep.value += 1;

  if(loadingStep.value >= 3) {
    exportToPDF(companyInfo.value, dataObj.value)
  }
}

const getCompanyInfo = async () => {
  await fetchCompanyInfo(companyId.value).then((res) => {
    companyInfo.value = res.data
  })
}

onMounted(() => {
  getCompanyInfo()
})
</script>